import Player from '../Player';

class PlayerManager {
  constructor(element) {
    this.players = {};
    this.container = element;
    this.clearPlayerStorage();
    this.initBindings();
  }

  initBindings() {
    const playerElements = this.container.querySelectorAll('[data-kjb-element="podcast-player"]');

    Array.prototype.forEach.call(playerElements, (element) => {
      this.buildPlayer(element);
    });
  }

  buildPlayer(element) {
    const playerId = element.getAttribute('data-episode-config');
    this.players[playerId] = new Player(element, this);
    return this.players[playerId];
  }

  playOnly(playerId) {
    Object.keys(this.players).filter(id => id !== playerId).forEach(this.pause.bind(this));
  }

  pause(playerId) {
    this.players[playerId].pause();
  }

  clearPlayerStorage() {
    for (let i = 0; i < window.localStorage.length; i += 1) {
      const key = window.localStorage.key(i);
      if (key.indexOf('pwp') === 0) {
        window.localStorage.removeItem(key);
      }
    }
  }
}

export default PlayerManager;

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('[data-kjb-component="PlayerManager"]');
  if (!elements) {
    return;
  }

  Array.prototype.forEach.call(elements, element => new PlayerManager(element));
});
